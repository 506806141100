import Vue from "vue";
import vailcode from "@utils/errcode";
import lang from "@i18n/i18nOption.json";
const { langDefault, langList } = lang;
import cookie from "js-cookie";

// import web3Wallet from "@web3Wallet/index";
// import web3Config from "@Web3WalletConfig/config";
import BigNumber from "bignumber.js";
export default {
  namespaced: true,
  state: {
    isMobile: true,
    isLang: "En",
    setting: {},

    balance: {}, //代币余额
    tonConnect: false,
    isSign: true
  },
  mutations: {
    setTheme: (state, payload)=> {
      state.theme = payload
    },
    setBtnTop(state, payload) {
      state.btnTop = payload;
    },
    SETLANG: (state, payload) => {
      state.isLang = payload;
    },
    SET_ISMOBILE: (state, payload) => {
      state.isMobile = payload;
    },
    SETSETTING: (state, payload) => {
      state.setting = payload;
    },

    CLEAR_CHECKWALLETHANDLE: (state) => {
      clearInterval(state.checkWalletHandle);
    },
    SET_CHECKWALLETHANDLE: (state, payload) => {
      state.checkWalletHandle = payload;
    },
    CLEARCHECKWALLETHANDLE: (state) => {
      clearInterval(state.checkWalletHandle);
    },
    setIsSign: (state, payload) => {
      state.isSign = payload
    },
    SET_BNB_BALANCE: (state, payload) => {
      state.bnbBalance = payload;
    },
    setTonConnect: (state, payload) => {
      state.tonConnect = payload;
    },
  },
  actions: {
    //获取配置
    getSetting: async ({ commit }) => {
      const rs = await Vue.prototype.$http.post("/system/init",{});
      vailcode(rs, () => {
        commit("SETSETTING",rs.data)
      });
    },
    doLogin:async ({ commit },[username,password]) => {
      const rs = await Vue.prototype.$http.post("/manage/login",{username,password});
      vailcode(rs, () => {
        cookie.set('token',rs.data)
        commit("setIsSign",true)
      });
    },
     //获取配置
    checkLogin: async ({ commit }) => {
      let token = cookie.get('token') || '';
      if(!token){
          commit("setIsSign",false)
      }else{
        const rs = await Vue.prototype.$http.post("/manage/check",{});
        if(rs.code !== 0){
          commit("setIsSign",false)
        }else{
          commit("setIsSign",true)
        }
      }
    },
  },
  getters: {
  },
};
